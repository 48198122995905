import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

import { apiRequestPaginated } from "src/apiRequest";

const DataTable = ({
  endpoint,
  columns,
  itemsPerPage = 10,
  urlSearchParams = {},
  refreshKey,
}) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [buttonWidth, setButtonWidth] = useState(0);
  const [filters, setFilters] = useState({});
  const [debouncedFilters, setDebouncedFilters] = useState(filters);
  const tempRef = useRef(null);

  const fetchData = (page, sortConfig, filters) => {
    const filtersToSend = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value !== "" && value !== null,
      ),
    );
    apiRequestPaginated(
      endpoint,
      {
        method: "GET",
      },
      page,
      itemsPerPage,
      {
        sort_by: sortConfig.key,
        sort_order: sortConfig.direction,
        ...filtersToSend,
        ...urlSearchParams,
      },
    )
      .then((response) => response.json())
      .then((d) => {
        setData(d.data);
        setTotalPages(Math.ceil(d.total_count / itemsPerPage));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "asc" };
    });
  };

  const handleSortClick = (e, accessor) => {
    e.preventDefault();
    handleSort(accessor);
  };

  const handleFilterChange = (value, filterKey) => {
    // Update the filters state immediately
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: value,
    }));
  };

  const renderSortIcon = (accessor) => {
    const sortUpClass =
      sortConfig.key === accessor && sortConfig.direction === "asc"
        ? "sort-control-icon sort-control-active"
        : "sort-control-icon sort-control-inactive";
    const sortDownClass =
      sortConfig.key === accessor && sortConfig.direction === "desc"
        ? "sort-control-icon sort-control-active"
        : "sort-control-icon sort-control-inactive";
    return (
      <div className="sort-control">
        <span className={sortUpClass}>
          <FontAwesomeIcon icon="sort-up" />
        </span>
        <span className={sortDownClass}>
          <FontAwesomeIcon icon="sort-down" />
        </span>
      </div>
    );
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxButtons = 10; // How many pages to show before collapsing and using ellipsis i.e in the case of 10, if there were 9 pages, it would show 9  buttons, but at 10, it would add ellipsis.
    const ellipsisThreshold = 4; // When to show ellipses (after x page)
    const surroundingPages = 1;

    if (totalPages <= maxButtons) {
      // if total page is less than or equal to maxbuttons, show all pages
      // Show all pages if total pages is small. ie if max is 8, and theres only 8 or less pages to show, show them all.
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // else, we need to figure out ellipsis
      // always gonna be one page at least
      pageNumbers.push(1);

      if (currentPage >= ellipsisThreshold + 1) {
        // control when to show first ellipsis, using ellipsis threshold
        pageNumbers.push("ellipsis-start");
      }

      // define the start and end number of pages in between ellipsis
      let start = 0;
      let end = 0;
      if (currentPage <= ellipsisThreshold) {
        // first interval strech
        start = 2;
        end = ellipsisThreshold + 1;
      } else if (totalPages - currentPage + 1 <= ellipsisThreshold) {
        // last interval strech
        start = totalPages - ellipsisThreshold;
        end = totalPages - 1;
      } else {
        // middle
        start = currentPage - surroundingPages;
        end = currentPage + surroundingPages;
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (currentPage <= totalPages - ellipsisThreshold) {
        pageNumbers.push("ellipsis-end");
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const calculateButtonWidth = () => {
    // need the delay because page rendering race. If I try to do this without, it'll set to 0, which is no good.
    setTimeout(() => {
      if (tempRef.current) {
        // Set the width based on the largest button
        setButtonWidth(tempRef.current.offsetWidth);
      }
    }, "500");
  };

  useEffect(() => {
    fetchData(currentPage, sortConfig, debouncedFilters);
  }, [currentPage, sortConfig, debouncedFilters, refreshKey]);

  useLayoutEffect(() => {
    calculateButtonWidth(); // Calculate button width after rendering the pagination
  }, [currentPage, totalPages]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters(filters);
    }, 300);

    return () => {
      clearTimeout(handler); // Clear timeout if filters change again
    };
  }, [filters]); // Run the effect whenever filters change

  return (
    <div className="data-card">
      <Table className="data-table" size="sm" responsive>
        <thead>
          <tr style={{ borderBottom: "white" }}>
            {columns.map((col) => (
              <th
                className={`datatable-header ${!col.accessor && "no-click"}`}
                key={col.accessor || col.Header}
                onClick={(e) =>
                  col.accessor && handleSortClick(e, col.accessor)
                }
              >
                <div className="d-flex align-items-center justify-content-between">
                  <span>
                    {col.Header} {col.accessor && renderSortIcon(col.accessor)}
                  </span>
                </div>
              </th>
            ))}
          </tr>
          <tr>
            {columns.map((col) => (
              <th key={`filter-${col.filterKey || col.Header}`}>
                {col.filterKey && (
                  <Form.Control
                    type="text"
                    placeholder={`Search ${col.Header}`}
                    value={filters[col.filterKey] || ""}
                    onChange={(e) =>
                      handleFilterChange(e.target.value, col.filterKey)
                    }
                    className="datatable-filter-input"
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {columns.map((col) => (
                <td className="datatable-cell" key={col.accessor || col.Header}>
                  {col.render ? col.render(row) : row[col.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Pagination>
          <Pagination.First
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {generatePageNumbers().map((page, index) => {
            if (page === "ellipsis-start" || page === "ellipsis-end") {
              return (
                <Pagination.Ellipsis
                  className="no-click"
                  key={index}
                  style={{ width: buttonWidth || "auto", flexShrink: 0 }}
                />
              );
            } else {
              return (
                <Pagination.Item
                  key={index}
                  active={page === currentPage}
                  onClick={() => setCurrentPage(page)}
                  ref={page === totalPages ? tempRef : null}
                  style={{ width: buttonWidth || "auto", flexShrink: 0 }}
                >
                  {page}
                </Pagination.Item>
              );
            }
          })}
          <Pagination.Next
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DataTable;
